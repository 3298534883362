import React from "react";
import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash/fp";
import { useCustomsScan } from "./useCustomsScan";
import { TaskSelect } from "./ScanType";
import { BarcodeInput } from "./BarcodeInput/BarcodeInput";

const CustomsScanContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.S3};
  width: calc(100vw - ${theme.spacing.S5}) // Prevent button from overflowing off screen from long task names
`
);

export const CustomsScan = () => {
  const { selectedTask, barcode, isLoading, setSelectedTask, setBarcode, handleCustomsScan } = useCustomsScan();

  return (
    <CustomsScanContainer>
      <TaskSelect selectedTask={selectedTask} setSelectedTask={setSelectedTask} />
      <BarcodeInput
        visible={!isEmpty(selectedTask)}
        disabled={isLoading || !selectedTask}
        barcode={barcode}
        selectedTask={selectedTask}
        setBarcode={setBarcode}
        handleCustomsScan={handleCustomsScan}
      />
    </CustomsScanContainer>
  );
};
