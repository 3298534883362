import { FC } from "react";
import { PackageOnHoldModal } from "./PackageOnHoldModal";
import { TrackingCodeNotFoundModal } from "./TrackingCodeNotFoundModal";
import { UnknownCustomsStatusModal } from "./UnknownCustomsStatusModal";
import { UnknownErrorModal } from "./UnknownErrorModal";
import { CustomsScanModal } from "./CustomsScanModal";
import { MasterCartonOnHoldModal } from "./MasterCartonOnHold";
import { MasterCartonNotFoundModal } from "./MasterCartonNotFound";
import { MasterCartonBarcodeInvalidModal } from "./MasterCartonBarcodeInvalid";

export const customsScanModalMap: Record<CustomsScanModal, FC<any>> = {
  [CustomsScanModal.PACKAGE_ON_HOLD]: PackageOnHoldModal,
  [CustomsScanModal.CUSTOMS_UNKNOWN_ERROR]: UnknownErrorModal,
  [CustomsScanModal.TRACKING_CODE_NOT_FOUND]: TrackingCodeNotFoundModal,
  [CustomsScanModal.UNKNOWN_CUSTOMS_STATUS]: UnknownCustomsStatusModal,
  [CustomsScanModal.MASTER_CARTON_ON_HOLD]: MasterCartonOnHoldModal,
  [CustomsScanModal.MASTER_CARTON_NOT_FOUND]: MasterCartonNotFoundModal,
  [CustomsScanModal.MASTER_CARTON_BARCODE_INVALID]: MasterCartonBarcodeInvalidModal,
};
