import { ModalGenericProps } from "@deliverr/ui-facility";
import { FC } from "react";
import { divertLaneModalMap } from "sortation/pages/DivertLaneScan/modals";
import { leftInBuildingScanModalMap } from "sortation/pages/LeftInBuildingScan/modals";
import { loadToTrailerModalMap } from "sortation/pages/LoadToTrailerScan/modals";
import { manifestModalMap } from "sortation/pages/Manifests/modals";
import { outboundModalMap } from "sortation/pages/Outbound/modals";
import { dockDoorScanV2ModalMap } from "./../../pages/DockDoorScanV2/modals/DockDoorScanV2ModalMap";
import { utilityScanModalMap } from "./../../pages/UtilityScan/modals/utilityScanModalMap";
import { customsScanModalMap } from "./../../pages/CustomsScan/modals/customsScanModalMap";

export const sortationModalMap: Record<string, FC<ModalGenericProps>> = {
  ...outboundModalMap,
  ...divertLaneModalMap,
  ...utilityScanModalMap,
  ...dockDoorScanV2ModalMap,
  ...leftInBuildingScanModalMap,
  ...loadToTrailerModalMap,
  ...manifestModalMap,
  ...customsScanModalMap,
};
