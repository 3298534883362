import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { CustomsScanModal } from "./CustomsScanModal";

export interface MasterCartonOnHoldModalProps {
  onHoldOrUnknownPackageCount: number;
}

export const MasterCartonOnHoldModal: React.FC<MasterCartonOnHoldModalProps> = ({ onHoldOrUnknownPackageCount }) => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.customsScan.masterCartonOnHoldModal.title" defaultMessage="On Hold" />}
      text={
        <FormattedMessage
          id="sortation.customsScan.masterCartonOnHoldModal.body"
          defaultMessage={
            "The scanned master carton has {onHoldOrUnknownPackageCount} package(s) that have not cleared customs or have an unknown customs status. Please identify uncleared customs package(s) using the package level customs scan."
          }
          values={{ onHoldOrUnknownPackageCount }}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(CustomsScanModal.MASTER_CARTON_ON_HOLD)}>
        <FormattedMessage id="sortation.customsScan.masterCartonOnHoldModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
