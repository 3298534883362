import { CustomsStatusError, CustomsStatusResponse, SortationCenterError } from "@deliverr/sortation-client";
import {
  SoundFx,
  createDangerNotification,
  createSuccessNotification,
  logError,
  logStart,
  validateString,
} from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { visibleSortationModalValue } from "sortation/components/SortationModal/SortationModalState";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { CustomsScanModal } from "./modals";
import { CustomsScan } from "./ScanType/ScansTypes";

const MASTER_CARTON_PREFIX = /^MC/;

export const useCustomsScan = () => {
  const { formatMessage } = useIntl();
  const { successResponse, customResponse, resetNotifications, addAutoCloseNotification } = useCommonFlow();
  const { handleSortationCenterError } = useSortationCenterError();
  const sortationId = useRecoilValue(sortationIdState);
  const sortationClient = useSortationClient();
  const { showModal, hideAllModals } = useSortationModal();
  const visibleModal = useRecoilValue(visibleSortationModalValue);

  const [selectedTask, setSelectedTask] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");

  const errorResponse = useMemo(() => customResponse(SoundFx.ERROR_FAST, "DANGER"), [customResponse]);

  const handlePackageCustomsScan = useCallback(
    async (code) => {
      try {
        const customsStatusResponse = await sortationClient.retrieveCustomsStatus(code);

        if (customsStatusResponse.customsStatus === CustomsStatusResponse.UNKNOWN_CUSTOMS_STATUS) {
          showModal(CustomsScanModal.UNKNOWN_CUSTOMS_STATUS, {});
        } else if (customsStatusResponse.customsStatus === CustomsStatusResponse.CUSTOMS_ON_HOLD) {
          showModal(CustomsScanModal.PACKAGE_ON_HOLD, {});
        } else if (customsStatusResponse.customsStatus === CustomsStatusResponse.CUSTOMS_CLEARED) {
          successResponse();
          addAutoCloseNotification(
            createSuccessNotification(
              formatMessage(
                {
                  id: "sortation.packageCustomsStatusScan.success",
                  defaultMessage: "Package {code} has been cleared by customs",
                },
                { code }
              )
            ),
            TOAST_AUTO_CLOSE
          );
        } else {
          showModal(CustomsScanModal.CUSTOMS_UNKNOWN_ERROR, {});
        }
      } catch (err) {
        if (err.code === CustomsStatusError.TRACKING_CODE_NOT_FOUND) {
          showModal(CustomsScanModal.TRACKING_CODE_NOT_FOUND, {});
        } else {
          showModal(CustomsScanModal.CUSTOMS_UNKNOWN_ERROR, {});
        }
      }
    },
    [addAutoCloseNotification, formatMessage, showModal, successResponse, sortationClient]
  );

  const handleMasterCartonCustomsScan = useCallback(
    async (code) => {
      const isValidMasterCarton = validateString(MASTER_CARTON_PREFIX);
      if (!isValidMasterCarton(code)) {
        showModal(CustomsScanModal.MASTER_CARTON_BARCODE_INVALID, {});
        return;
      }

      try {
        const customsStatusResponse = await sortationClient.retrieveMasterCartonCustomsStatus(code);
        const clearedPackageCount = customsStatusResponse.CUSTOMS_CLEARED?.length || 0;
        const onHoldOrUnknownPackageCount =
          (customsStatusResponse.CUSTOMS_HOLD?.length || 0) + (customsStatusResponse.CUSTOMS_UNKNOWN?.length || 0);

        if (onHoldOrUnknownPackageCount > 0) {
          showModal(CustomsScanModal.MASTER_CARTON_ON_HOLD, { onHoldOrUnknownPackageCount });
          return;
        }

        successResponse();
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.masterCartonCustomsStatusScan.success",
                defaultMessage:
                  "The scanned master carton has {clearedPackageCount} package(s) that have been cleared by customs",
              },
              { clearedPackageCount }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        if (err.code === CustomsStatusError.MASTER_CARTON_NOT_FOUND) {
          showModal(CustomsScanModal.MASTER_CARTON_NOT_FOUND, {});
        } else {
          showModal(CustomsScanModal.CUSTOMS_UNKNOWN_ERROR, {});
        }
      }
    },
    [addAutoCloseNotification, formatMessage, showModal, successResponse, sortationClient]
  );

  const [handleCustomsScanState, handleCustomsScan] = useAsyncFn(
    async (input: string) => {
      // Don't allow scan if there's a visible modal
      if (visibleModal) {
        return;
      }

      const ctx = logStart({ fn: "useCustomsScan.handleCustomsScan", input, sortationId });

      // Parse barcode to extract tracking code for 2D data matrix scans
      const code = parseTrackingInput(input);

      // Normalize state
      setBarcode(code);
      resetNotifications();
      hideAllModals();

      try {
        if (selectedTask === CustomsScan.PACKAGE_CUSTOMS_SCAN) {
          await handlePackageCustomsScan(code);
        } else if (selectedTask === CustomsScan.MASTER_CARTON_CUSTOMS_SCAN) {
          await handleMasterCartonCustomsScan(code);
        }
      } catch (err) {
        logError(ctx, err);
        if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
          handleSortationCenterError();
          return;
        }

        // Error flash, sound, and toast notification
        errorResponse();
        addAutoCloseNotification(
          createDangerNotification(
            formatMessage({
              id: "sortation.customsscan.error",
              defaultMessage: "An unknown error occurred. Please try again.",
            })
          ),
          TOAST_AUTO_CLOSE
        );
      } finally {
        // Reset barcode state
        setBarcode("");
      }
    },
    [selectedTask, sortationId]
  );

  return {
    selectedTask,
    setSelectedTask,
    barcode,
    setBarcode,
    isLoading: handleCustomsScanState.loading,
    handleCustomsScan,
  };
};
