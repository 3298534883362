export interface ScanType {
  type: CustomsScan;
  name: string;
}

export enum CustomsScan {
  PACKAGE_CUSTOMS_SCAN = "PACKAGE_CUSTOMS_SCAN",
  MASTER_CARTON_CUSTOMS_SCAN = "MASTER_CARTON_CUSTOMS_SCAN",
}

export const CustomsScanNames: Record<CustomsScan, string> = {
  [CustomsScan.PACKAGE_CUSTOMS_SCAN]: "Package Barcode",
  [CustomsScan.MASTER_CARTON_CUSTOMS_SCAN]: "Master Carton Barcode",
};

export const CustomsScanList: ScanType[] = [
  {
    type: CustomsScan.PACKAGE_CUSTOMS_SCAN,
    name: CustomsScanNames.PACKAGE_CUSTOMS_SCAN,
  },
  {
    type: CustomsScan.MASTER_CARTON_CUSTOMS_SCAN,
    name: CustomsScanNames.MASTER_CARTON_CUSTOMS_SCAN,
  },
];
