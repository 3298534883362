import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { CustomsScanModal } from "./CustomsScanModal";

export const UnknownErrorModal: React.FC = () => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.customsScan.unknownErrorModal.title" defaultMessage="Unknown Error" />}
      text={
        <FormattedMessage
          id="sortation.customsScan.unknownErrorModal.body"
          defaultMessage={"An unknown error occurred. Please try again."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(CustomsScanModal.CUSTOMS_UNKNOWN_ERROR)}>
        <FormattedMessage id="sortation.customsScan.unknownErrorModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
